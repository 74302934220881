import './assets/css/map.css'
import './assets/css/App.css';

import Map from './components/Map';

function App() {
  return (
    <div className="App" style={{width:'100%',height:'100%'}}>
      <Map/>
    </div>
  );
}

export default App;
