import React from 'react'
import SVG from './SVG'
import DemoSvg from './DemoSvg'

function Map() {
  return (
    <div data-component="zoomer" className="zoomer">
        {/* <div data-component="zoomer__controls" className="zoomer__controls">
            <button className="zoomer__control--reset " aria-label="Reset Focus on Interactive Seat Map">
                <svg className="BaseSvg-sc-yh8lnd-0 HouseIcon___StyledBaseSvg-sc-1hiudpu-0 ckLyyv reset" viewBox="0 0 24 24" width="1.5em" height="1.5em" aria-hidden="true" focusable="false">
                    <path d="m12 1.1 10 6.5V23H2V7.6zM3.5 8.42V21.5h5v-7.75h7v7.75h5V8.41L12 2.9zM14 21.5v-6.25h-4v6.25zM9 10.75h6v-1.5H9z"></path>
                </svg>
            </button>
            <button className="zoomer__control--zoomin" aria-label="Zoom In on Interactive Seat Map">
                <svg className="BaseSvg-sc-yh8lnd-0 PlusIcon___StyledBaseSvg-sc-1vsme3u-0 ckLyyv zoomin" viewBox="0 0 24 24" width="1.5em" height="1.5em" aria-hidden="true" focusable="false">
                    <path d="M11.75 13.25V23h1.5v-9.75H23v-1.5h-9.75V1h-1.5v10.75H1v1.5z"></path>
                </svg>
            </button>
            <button className="zoomer__control--zoomout " aria-label="Zoom Out on Interactive Seat Map">
                <svg className="BaseSvg-sc-yh8lnd-0 MinusIcon___StyledBaseSvg-sc-1btgke4-0 ckLyyv zoomout" viewBox="0 0 24 24" width="1.5em" height="1.5em" aria-hidden="true" focusable="false">
                    <path d="M1 11.25h22v1.5H1z"></path>
                </svg>
            </button>
        </div> */}
        <div style={{width:'100%',height:'100%'}}>
            {/* <SVG/> */}
            <DemoSvg/>
        </div>
    </div>

  )
}

export default Map